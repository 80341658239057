.wrapper {
    background: rgb(255, 255, 255);
    display: flex;
    width: 100%;
}

.aside {
    width: 55%;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: rgb(68, 68, 68);
    position: relative;
    height: 100vh;
    background-image: url(./aside.webp);
    background-position: left center;
    background-size: cover;
}

.aside .content {
    display: flex;
    position: absolute;
    height: 100%;
    top: 0px;
    left: 100px;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.aside .hero {
    padding-top: 60px;
}

.aside .greeting {
    margin: 110px 0px 0px;
    font-family: Poppins;
    font-weight: 700;
    line-height: 1.125em;
    color: white;
    max-width: 80%;
    font-size: 7vw;
}

.aside .copyrights {
    margin: 0px;
    font-family: "Open Sans", Poppins, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    font-size: 16px;
    color: white;
    margin-bottom: 40px;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 45%;
    justify-content: center;
    gap: 20px;
}

.button {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: "Open Sans", Poppins, sans-serif;
    line-height: 1.75;
    min-width: 64px;
    border-radius: 4px;
    transition:
        background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgb(255, 255, 255);
    background-color: rgb(63, 110, 183);
    box-shadow:
        rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
        rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    width: 350px;
    padding: 8px 22px;
    font-size: 15px;
    letter-spacing: 0.46px;
    font-weight: 600;
}

.intro {
    margin: 0px;
    line-height: 1.5;
    display: inline-block;
    font-size: 34px;
    font-family: Poppins;
    font-weight: 700;
    letter-spacing: -0.68px;
}
